<template>
  <div>
    <div class="flex-align-center">
      <el-button type="primary" @click="handleAddContents">选择活动</el-button>
      <p slot="info" class="img-tips">
        添加过往活动可关联一个相册，不关联相册则默认使用活动封面图，如无相册可到【插件-相册】中创建；无活动时不展示
      </p>
    </div>
    <el-table
      id="sortTable3"
      row-key="name"
      :data="modelList"
      class="thead-light"
      stripe
      :style="{ marginTop: '10px' }"
    >
      <el-table-column
        v-for="(item, index) in sortColumn"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        :min-width="item.min_width"
        :sortable="item.sortable"
      >
        <template slot-scope="scope">
          <!--排序 -->
          <div v-if="item.prop === 'sort'">
            <i class="el-icon-sort"></i>
          </div>

          <!-- 头像 -->
          <list-image
            v-else-if="item.prop === 'image'"
            :src="scope.row[item.prop]"
            borderRadius="50%"
            fit="cover"
          ></list-image>
          <span v-else>{{ scope.row[item.prop] | placeholder }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160" fixed="right">
        <template slot-scope="scope">
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="handleEditContents(scope.row, scope.$index)"
            >关联相册
          </el-button>
          <el-button
            style="color: #3576ff"
            size="small"
            type="text"
            @click="handleDelContents(scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <AddActiviesSelector
      v-model="openAddContents"

      :form="{
        status: 2,
      }"
      :Contents="modelList"
      @changeSelect="changeSelect"
    ></AddActiviesSelector>
    <SelectDataDialog
      v-model="showDialog"
      @change="photoChangeSelect"
      componentType="st-activity-photo"
    ></SelectDataDialog>
  </div>
</template>

<script>
import AddActiviesSelector from './AddActiviesSelector'
import ListImage from '@/base/components/List/ListImage'
import Sortable from 'sortablejs/modular/sortable.core.esm.js'
import SelectDataDialog from '@/modules/activity/components/ActivityList/SelectDataDialog'
import { updatePhotoActivityId } from '../../api/CycIntroducePage'
export default {
  components: {
    AddActiviesSelector,
    ListImage,
    SelectDataDialog,
  },
  model: {
    prop: 'sortList',
    event: 'changeSortList',
  },
  props: {
    sortList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortColumn: [
        { label: '拖拽排序', prop: 'sort', min_width: 100 },
        { label: '活动名称', prop: 'name', min_width: 100 },
        { label: '报名人数', prop: 'activity_users_count', min_width: 100 },
        { label: '关联相册', prop: 'photo_activity_name', min_width: 100 },
      ],
      openAddContents: false,
      currentI: 0,
      showDialog: false,
      setRow: {},
    }
  },
  computed: {
    modelList: {
      get() {
        return this.$props.sortList
      },
      set(val) {
        this.$emit('changeSortList', val)
      },
    },
  },
  methods: {
    //初始化拖拽表格
    initSortable() {
      this.$nextTick(() => {
        const sortTable = document.querySelector(
          '#sortTable3 .el-table__body>tbody'
        )
        console.log(sortTable)
        this.$sortTable2 = Sortable.create(sortTable, {
          animation: 150,
          ghostClass: 'sortable-ghost',
          onEnd: (event) => {
            console.log(event.oldIndex, event.newIndex)
            if (event.oldIndex === event.newIndex) {
              return
            }
            // 拿到拖拽的item
            const oldItem = this.modelList[event.oldIndex]
            // 删除原本位置
            this.modelList.splice(event.oldIndex, 1)
            // 添加到新的位置
            this.modelList.splice(event.newIndex, 0, oldItem)
          },
        })
        //阻止火狐拖拽新建新页面
        document.body.addEventListener('drop', this.preventDrop, false)
      })
    },
    //销毁拖拽表格
    destroySortable() {
      if (this.$sortTable2) this.$sortTable2.destroy()
      document.body.removeEventListener('drop', this.preventDrop, false)
    },
    //阻止默认drop事件
    preventDrop(event) {
      event.preventDefault()
      event.stopPropagation()
    },
    handleAddContents() {
      this.openAddContents = true
    },
    changeSelect(list) {
      console.log(list)
      this.modelList = [...list, ...this.modelList]
      this.modelList = [
        ...list.map((t) => {
          return {
            ...t,
            id: t.hashid,
          }
        }),
        ...this.modelList,
      ]
    },
    // 删除
    handleDelContents(row) {
      this.$confirm('是否删除此活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let index = this.modelList.findIndex((item) => item.name === row.name)
          this.modelList.splice(index, 1)
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
        })
        .catch(() => {})
    },
    handleEditContents(row, index) {
      this.setRow = row
      this.setIndex = index
      this.showDialog = true
    },
    photoChangeSelect(selectItem) {
      console.log(selectItem)
      updatePhotoActivityId({
        id: this.setRow.id,
        photo_activity_id: selectItem[0].id,
      }).then((res) => {
        this.modelList.splice(this.setIndex, 1, {
          ...this.modelList[this.setIndex],
          photo_activity_name: selectItem[0].activity_name,
        })
        console.log(res)
      })
    },
  },
  mounted() {
    this.initSortable()
  },
  beforeDestroy() {
    //销毁拖拽表格
    this.destroySortable()
  },
}
</script>

<style lang="scss" scoped>
.img-tips {
  margin-left: 20px;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>
