<template>
  <div class="page" v-loading="loading">
    <el-form
      class="save-content"
      label-width="100px"
      :model="form"
      :rules="rules"
      ref="saveForm"
    >
      <el-form-item label="商会活动：">
        <el-radio-group v-model="form.enable">
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="0">关闭</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item v-show="form.enable" label="标题别名：">
        <el-input style="max-width: 400px;" v-model="form.alias" placeholder="请输入" />
      </el-form-item>
      <div v-show="form.enable">
        <el-form-item label="下场活动：">
          <NextActiviesTable
            v-if="form.next_activities"
            v-model="form.next_activities"
          />
        </el-form-item>
        <el-form-item label="过往活动：">
          <HistotyActiviesTable
            v-if="form.history_activities"
            v-model="form.history_activities"
          />
        </el-form-item>
      </div>
    </el-form>
    <FixedActionBar>
      <el-button @click="cancel">取消</el-button>
      <el-button @click="$emit('openCode')">预览</el-button>
      <el-button type="primary" @click="saveConfig">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from '@/base/layout/FixedActionBar'
import CycIntroducePage from '../../mixins/CycIntroducePage'
import HistotyActiviesTable from '../../components/CycIntroducePage/HistotyActiviesTable'
import NextActiviesTable from '../../components/CycIntroducePage/NextActiviesTable'
export default {
  mixins: [CycIntroducePage],
  components: { FixedActionBar, HistotyActiviesTable, NextActiviesTable },
  model: {
    prop: 'previewForm',
    event: 'setPreviewForm',
  },
  props: {
    previewForm: Object,
    formData: Object,
  },
  data() {
    return {
      key: 'activity',
      loading: true,
      rules: {},
    }
  },
  computed: {
    form: {
      get() {
        console.log(this.$props)
        return this.$props.previewForm[this.key]
      },
      set(val) {
        let formData = { ...this.$props.previewForm }
        formData[this.key] = val
        this.$emit('setPreviewForm', formData)
      },
    },
  },
  methods: {
    saveConfig() {
      this.$refs.saveForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$emit('saveConfig', { key: this.key, config: this.form })
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              let isError = document.getElementsByClassName('is-error')
              console.log('isError', isError)
              isError[0].scrollIntoView({ block: 'center' })
            })
          }, 100)
          this.$message.error('请填写完整信息')
          return false
        }
      })
    },
    cancel() {
      this.form = JSON.parse(JSON.stringify(this.$props.formData[this.key]))
    },
  },
  watch: {
    '$props.formData.activity': {
      handler(val) {
        this.form = JSON.parse(JSON.stringify(val))
        this.loading = false
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
}
</script>

<style></style>
